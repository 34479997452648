import loadable from '@loadable/component';
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Route, Switch } from 'react-router-dom';

const ExperimentsAdmin = loadable(() => import('@routes/Dash/Admin/Experiments'), { ssr: false });
const MDXMigrationAdmin = loadable(() => import('@routes/Dash/Admin/MDXMigration'), { ssr: false });

const AdminRouter = () => (
  <Switch>
    <Route path="/admin/experiments">
      <ExperimentsAdmin />
    </Route>
    <Route path="/admin/mdx-migration/:slug?">
      <MDXMigrationAdmin />
    </Route>
    <Route path="/admin">
      <MetaTags>
        <title>Admin</title>
      </MetaTags>
      {/* Admin Fallback Route */}
    </Route>
  </Switch>
);

export default AdminRouter;
